import './src/styles/global.css'
import * as Cookies from 'js-cookie'
import urls from 'src/urls.json'

export function onClientEntry() {
  if(window.location.pathname !== '/' || !window.location.hostname.includes('peg.co')) return

  const domain = window.location.hostname.split('.').slice(-2).join('.')
  const email = Cookies.get('email', { domain })

  if(email) window.location = urls.login
}
